import * as React from 'react';

import MainLayout from '../components/Layout/MainLayout';
import HeaderSchedulePage from '../shared/Header/HeaderSchedulePage';
import MySEO from '../shared/MySEO';

const DangKyPage = () => {
  return (
    <MainLayout
      hideSideCTA
      noHeader
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO />
    </MainLayout>
  );
};

export default DangKyPage;
